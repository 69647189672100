<template>
  <div class="stripe-card-item-card tw-flex tw-items-center tw-text-secondary-text tw-text-sm tw-w-full">
    <slot name="prefix" />
    <div
      v-text="holderName"
      class="tw-flex-1 tw-truncate"
      data-test="holder-name"
    />
    <div
      class="tw-ml-4"
      data-test="last-four"
    >
      <span
        class="tw-hidden md:tw-inline"
        v-text="'.... .... .... '"
      />
      <span v-text="lastFour" />
    </div>
    <div
      v-text="expiresAt"
      class="tw-ml-4"
      data-test="expires-at"
    />
    <picture
      v-if="formattedType"
      data-test="type"
      class="tw-ml-4"
    >
      <img
        :src="require(`@/assets/img/cards/${formattedType}@1x.png`)"
        :srcset="`${require(`@/assets/img/cards/${formattedType}@1x.png`)} 1x, ${require(`@/assets/img/cards/${formattedType}@2x.png`)} 2x`"
        alt=""
        height="20"
        width="33"
        class="tw-object-contain"
      >
    </picture>
    <img
      v-else
      src="@/assets/img/cards/blank.svg"
      height="20"
      width="33"
      class="tw-object-contain tw-ml-4"
      alt=""
      data-test="empty-card"
    >
    <slot name="suffix" />
  </div>
</template>

<script>
  const SUPPORTED_CARD_BRANDS = ['mastercard', 'visa', 'amex', 'diners', 'discover', 'jcb', 'unionpay']

  /**
   * @module component - StripeCardItemCard
   * @param {string} holderName
   * @param {number} lastFour
   * @param {string} expiresAt
   * @param {string} type
   */
  export default {
    name: 'StripeCardItemCard',
    props: {
      holderName: {
        type: String,
        required: true
      },
      lastFour: {
        type: [Number, String],
        required: true
      },
      expiresAt: {
        type: String,
        required: true
      },
      type: {
        type: String,
        required: true
      }
    },
    computed: {
      /**
       * Converts the Stripe card brand name to our supported card names format.
       * @function formattedType
       * @returns {string|null}
       */
      formattedType () {
        const formattedCardType = this.type.toLowerCase().replace(' ', '-')

        if (SUPPORTED_CARD_BRANDS.includes(formattedCardType)) {
          return formattedCardType
        }

        console.error(`The specified card type "${formattedCardType}" is not supported by our integration.`)

        return null
      }
    }
  }
</script>
