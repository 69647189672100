<template>
  <stripe-element
    ref="stripe-element"
    :stripe-elements="stripeElements"
    :options="{
      showIcon: true
    }"
    :disabled="disabled"
    :label="$t('app.labels.credit_card.number')"
    class="stripe-card-number"
    type="cardNumber"
    target="card-number"
    has-icon
  >
    <div id="card-number" />
  </stripe-element>
</template>

<script>
  import StripeElement from '@/components/Stripe/StripeElement/index.vue'

  /**
   * @module component - StripeCardNumber
   * @param {any} stripeElements
   */
  export default {
    name: 'StripeCardNumber',
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      stripeElements: {
        type: Object,
        default: null
      }
    },
    components: {
      StripeElement
    }
  }
</script>
