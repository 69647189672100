<template>
  <div>
    <slot
      :stripe-elements="stripeElements"
    />
  </div>
</template>

<script>
  /**
   * @module component - StripeElementGroup
   * @param {object} stripe
   */
  export default {
    name: 'StripeElementGroup',
    props: {
      stripe: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        stripeElements: null
      }
    },
    watch: {
      stripe () {
        this.createElements()
      }
    },
    mounted () {
      this.createElements()
    },
    methods: {
      createElements () {
        if (this.stripe) {
          this.stripeElements = this.stripe.elements({
            locale: this.$i18n.locale
          })
        }
      }
    }
  }
</script>
