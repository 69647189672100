import { ref, onMounted } from '@vue/composition-api'
import { loadStripe } from '@stripe/stripe-js'

import Config from '@/services/Config'

export default function useStripe () {
  /** @type {import('@vue/composition-api').Ref<import('@stripe/stripe-js').Stripe|null>} */
  const stripe = ref(null)
  const retries = ref(0)

  async function tryLoad () {
    try {
      /**
       * Since the Stripe script was already included in the index.html file,
       * the loadScript call will re-use the same script tag and not create a new one.
       **/
      stripe.value = await loadStripe(Config.get('stripe.publishableKey'))
    } catch (e) {
      /**
       * We would like to retry to load the Stripe script when we detect
       * the "Failed to load Stripe" error.
       * This is to mitigate the issue https://github.com/stripe/stripe-js/issues/26
       **/
      if (e.message.includes('Failed to load Stripe')) {
        retries.value++
        if (retries.value < 3) {
          console.warn('Failed to load Stripe, retrying...')

          await new Promise(resolve => setTimeout(resolve, 2000))
          tryLoad()
        } else {
          throw e
        }
      } else {
        throw e
      }
    }
  }

  onMounted(async () => {
    await tryLoad()
  })

  return {
    stripe
  }
}
