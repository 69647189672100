<template>
  <stripe-element
    ref="stripe-element"
    :stripe-elements="stripeElements"
    :label="$t('app.labels.credit_card.expiry')"
    :disabled="disabled"
    class="stripe-card-expiry"
    type="cardExpiry"
    target="card-expiry"
  >
    <div id="card-expiry" />
  </stripe-element>
</template>

<script>
  import StripeElement from '@/components/Stripe/StripeElement/index.vue'

  /**
   * @module component - StripeCardExpiry
   * @param {any} stripeElements
   */
  export default {
    name: 'StripeCardExpiry',
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      stripeElements: {
        type: Object,
        default: null
      }
    },
    components: {
      StripeElement
    }
  }
</script>
