<template>
  <div
    :class="{
      'invoices-pay-dialog-payment-status--success': success,
      'invoices-pay-dialog-payment-status--error': !success
    }"
    class="invoices-pay-dialog-payment-status tw-flex tw-flex-col tw-items-center tw-w-full tw-py-16"
  >
    <picture class="tw-mb-8">
      <img
        :src="success
          ? require('@/assets/img/icons/approved.svg')
          : require('@/assets/img/icons/rejected.svg')"
        width="70"
        height="70"
        alt=""
        data-test="icon"
      >
    </picture>
    <h1
      v-text="title"
      class="tw-text-2xl tw-mb-3"
      data-test="title"
    />
    <div
      class="md:tw-w-1/2 tw-text-center tw-mb-2"
      data-test="content"
    >
      <slot />
    </div>

    <ui-button
      variant="light"
      data-test="button"
      @click="$emit('click')"
    >
      {{ buttonTitle }}
    </ui-button>
  </div>
</template>

<script>
  /**
   * @module component - InvoicesPayDialogPaymentStatus
   * @param {string} title
   * @param {string} buttonTitle
   * @param {boolean} [success=false]
   */
  export default {
    name: 'InvoicesPayDialogPaymentStatus',
    props: {
      title: {
        type: String,
        required: true
      },
      buttonTitle: {
        type: String,
        required: true
      },
      success: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss" scoped>
.invoices-pay-dialog-payment-status {
  position: relative;
}
.invoices-pay-dialog-payment-status::after {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  content: '';
  height: 20px;
}
.invoices-pay-dialog-payment-status--success::after {
  --tw-bg-opacity: 1;
  background-color: rgba(150, 191, 49, var(--tw-bg-opacity));
}
.invoices-pay-dialog-payment-status--error::after {
  --tw-bg-opacity: 1;
  background-color: rgba(215, 40, 63, var(--tw-bg-opacity));
}
</style>
